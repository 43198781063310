<template>
  <div>
    <ul class="mt-2 stores__grid">
      <li
        v-for="store in stores.filter((x) => x.role_resource_type === 'Store')"
        :key="store.id"
      >
        <b-card class="mt-1 text-center">
          <div class="d-flex justify-content-center mt--5 mb-1">
            <b-avatar
              :src="`${store.role_resource_logo}`"
              size="85"
              :to="{
                name: 'POS',
                params: { store_id: store.role_resource_id },
              }"
            />
          </div>
          <b-link
            class="store-name"
            :to="{ name: 'POS', params: { store_id: store.role_resource_id } }"
          >
            <h2>
              {{ store.role_resource_name }}
            </h2>
          </b-link>
          <b-button
            size="lg"
            variant="primary"
            class="my-2"
            :to="{ name: 'POS', params: { store_id: store.role_resource_id } }"
          >
            <i class="fas fa-cash-register mr-1" />
            Punto de venta
          </b-button>
          <open-close-store :store="store" class="mt-1" />
        </b-card>
      </li>
    </ul>
  </div>
</template>

<script>
import OpenCloseStore from "./StoresButtonOpenClose.vue"

export default {
  components: {
    OpenCloseStore,
  },
  data() {
    return {
      stores: [],
      userData: JSON.parse(localStorage.getItem("userData")),
    }
  },
  beforeMount() {
    this.stores = this.userData.scoped_roles
  },
}
</script>

<style lang="scss" scoped>
.mt--5 {
  margin-top: -3rem;
}
.store-name {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.3rem;
  font-weight: 600;
}

.stores__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
  list-style: none;
  margin: 0;
}
</style>
