<template>
  <div>
    <b-button-group size="sm" class="d-flex flex-wrap">
      <b-button
        v-b-modal="`closeStoreModal-${store.role_resource_id}`"
        variant="danger"
        class="custom-button"
      >
        Cerrar tienda
      </b-button>
      <b-button
        v-b-modal="`openStoreModal-${store.role_resource_id}`"
        variant="success"
        class="custom-button"
      >
        Abrir tienda
      </b-button>
      <b-button
        v-b-modal="`cashWithdrawalModal-${store.role_resource_id}`"
        variant="warning"
        class="custom-button"
      >
        Retirar efectivo
      </b-button>
    </b-button-group>
    <b-modal
      :id="`openStoreModal-${store.role_resource_id}`"
      title="Abrir tienda"
      size="sm"
      :ok-disabled="cash_inicial < 0 || cash_inicial === null || cash_inicial === ''"
      @ok="handleOpenStore({storeId: store.role_resource_id, cash: cash_inicial})"
    >
      <b-form class="my-2">
        <h1 class="text-center display-3">
          💰
        </h1>
        <h3 class="text-center mb-1">
          ¿Cuánto efectivo hay en la caja?
        </h3>
        <b-input-group>
          <b-input-group-prepend is-text>
            $
          </b-input-group-prepend>
          <b-form-input
            v-model="cash_inicial"
            type="number"
          />
        </b-input-group>
      </b-form>
    </b-modal>
    <b-modal
      :id="`closeStoreModal-${store.role_resource_id}`"
      title="Cerrar tienda"
      size="sm"
      :ok-disabled="cash_final < 0 || cash_final === null || cash_final === ''"
      @ok="handleCloseStore({storeId: store.role_resource_id, cash: cash_final})"
    >
      <b-form class="my-2">
        <h1 class="text-center display-3">
          🏃💨
        </h1>
        <h3 class="text-center mb-1">
          ¿Cuánto efectivo hay en la caja?
        </h3>
        <b-input-group>
          <b-input-group-prepend is-text>
            $
          </b-input-group-prepend>
          <b-form-input
            v-model="cash_final"
            type="number"
          />
        </b-input-group>
      </b-form>
    </b-modal>
    <b-modal
      :id="`cashWithdrawalModal-${store.role_resource_id}`"
      title="Retirar efectivo"
      size="sm"
      :ok-disabled="cash_security_withdrawal < 0 || cash_security_withdrawal === null || cash_security_withdrawal === ''"
      @ok="handleCashWithdrawal({storeId: store.role_resource_id, cash: cash_security_withdrawal})"
    >
      <b-form class="my-2">
        <h1 class="text-center display-3">
          💰
        </h1>
        <h3 class="text-center mb-1">
          ¿Cuánto efectivo estás retirando?
        </h3>
        <b-input-group>
          <b-input-group-prepend is-text>
            $
          </b-input-group-prepend>
          <b-form-input
            v-model="cash_security_withdrawal"
            type="number"
          />
        </b-input-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BModal,
  BForm,
  BFormInput,
  BButton,
  BButtonGroup,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BButton,
    BButtonGroup,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    store: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      cash_inicial: null,
      cash_final: null,
      cash_security_withdrawal: null,
    }
  },
  methods: {
    ...mapActions('stores', [
      'openStore',
      'closeStore',
      'cashWithdrawal',
    ]),
    handleOpenStore(data) {
      this.openStore(data)
        .then(() => {
          this.$router.push({ name: 'POS', parmams: { store_id: data.store_id } })
        })
        .catch(error => {
          error.response.data.messages.base.forEach(element => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: element,
              },
            })
          })
        })
    },
    handleCloseStore(data) {
      this.closeStore(data)
        .then(() => {
          this.$swal({
            title: '¡Cierre de dia exitoso!',
            text: 'Grácias.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          error.response.data.messages.base.forEach(element => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: element,
              },
            })
          })
        })
    },
    handleCashWithdrawal(data) {
      this.cashWithdrawal(data)
        .then(() => {
          this.$swal({
            title: '¡Retiro de efectivo exitoso!',
            text: 'Grácias.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          error.response.data.messages.base.forEach(element => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: element,
              },
            })
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-button {
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 5px;
}

.custom-button:focus,
.custom-button:active {
  box-shadow: none;
  outline: none;
}

/* Custom button colors */
.custom-button.variant-danger {
  background-color: #e53935;
  color: #ffffff;
  border: 2px solid #e53935;
}

.custom-button.variant-danger:hover {
  background-color: #c62828;
  border-color: #c62828;
}

.custom-button.variant-success {
  background-color: #43a047;
  color: #ffffff;
  border: 2px solid #43a047;
}

.custom-button.variant-success:hover {
  background-color: #2e7d32;
  border-color: #2e7d32;
}

.custom-button.variant-warning {
  background-color: #ffb300;
  color: #ffffff;
  border: 2px solid #ffb300;
}

.custom-button.variant-warning:hover {
  background-color: #ffa000;
  border-color: #ffa000;
}

</style>
